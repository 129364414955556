export default function ({ store, $axios, $cookies }, inject) {

    /**
     * Get individual fields from the member object.
     * @param {string[]} fields - The fields to retrieve.
     * @returns {Promise<any>} The fields data.
     */
    async function get(fields) {
        return await store.dispatch('member/getFields', { fields, $axios, $cookies });
    }

    // ## NOTIFICATIONS ##
    /**
     * Get notifications count.
     * @returns {Promise<number>} The count of notifications.
     */
    async function getNotificationsCount() {
        return await store.dispatch('member/getNotificationsCount', { $axios, $cookies });
    }

    /**
     * Get notifications.
     * @returns {Promise<any[]>} The list of notifications.
     */
    async function getNotifications() {
        return await store.dispatch('member/getNotifications', { $axios, $cookies });
    }

    /**
     * Mark notifications as read.
     * @param {string[]} notificationIds - The IDs of the notifications to mark as read.
     * @returns {Promise<void>}
     */
    async function markNotificationsAsRead(notificationIds) {
        return await store.dispatch('member/markNotificationsAsRead', { notificationIds, $axios, $cookies });
    }

    // ## COLLECTIONS ##
    /**
     * Get collections.
     * @returns {Promise<any[]>} The list of collections.
     */
    async function getCollections() {
        return await store.dispatch('member/getCollections', { $axios, $cookies });
    }

    /**
     * Add a new collection.
     * @param {string} name - The name of the collection.
     * @returns {Promise<void>}
     */
    async function addCollection(name) {
        return await store.dispatch('member/addCollection', { name, $axios, $cookies });
    }

    /**
     * Add a course to a collection.
     * @param {string} collectionId - The ID of the collection.
     * @param {array} courseId - The array of course IDs to add to the collection.
     * @returns {Promise<void>}
     */
    async function addCourseToCollection(collectionId, courseId) {
        return await store.dispatch('member/addCourseToCollection', { collectionId, courseId, $axios, $cookies });
    }

    /**
     * Remove a course from a collection.
     * @param {string} collectionId - The ID of the collection.
     * @param {string} courseId - The ID of the course.
     * @returns {Promise<void>}
     */
    async function removeCourseFromCollection(collectionId, courseId) {
        return await store.dispatch('member/removeCourseFromCollection', { collectionId, courseId, $axios, $cookies });
    }

    /**
     * Remove a collection.
     * @param {string} collectionId - The ID of the collection.
     * @returns {Promise<void>}
     */
    async function removeCollection(collectionId) {
        return await store.dispatch('member/removeCollection', { collectionId, $axios, $cookies });
    }

    /**
     * Get collections for a specific item.
     * @param {string} itemId - The ID of the item.
     * @returns {Promise<any[]>} The list of collections.
     */
    async function getCollectionsForItem(itemId) {
        return await store.dispatch('member/getCollectionsForItem', { itemId, $axios, $cookies });
    }

    /**
     * Get items for a specific collection.
     * @param {string} collectionId - The ID of the collection.
     * @returns {Promise<any[]>} The list of items within the collection.
     */
    async function getCollection(collectionId) {
        return await store.dispatch('member/getCollection', { collectionId, $axios, $cookies });
    }
    
    /**
     * Get items for a specific collection.
     * @param {string} collectionId - The ID of the collection.
     * @returns {Promise<any[]>} The list of items within the collection.
     */
       async function archiveItem(itemId) {
        return await store.dispatch('member/archiveItem', { itemId, $axios, $cookies });
    }

    /**
     * 
     */
    async function sendPinToEmail(email, key) {
        return await store.dispatch('member/sendPinToEmail', { email, key, $axios, $cookies });
    }

    /**
     * Remove an archived item.
     * @param {string} itemId - The ID of the item.
     * @returns {Promise<void>}
     */
    async function removeArchivedItem(itemId) {
        return await store.dispatch('member/removeArchivedItem', { itemId, $axios, $cookies });
    }

    /**
     * Get available collections for a specific item.
     * @param {string} itemId - The ID of the item.
     * @returns {Promise<any[]>} The list of available collections.
     */
    async function getAvailableCollectionsForItem(itemId) {
        return await store.dispatch('member/getAvailableCollectionsForItem', { itemId, $axios, $cookies });
    }

    /**
     * Add a course to favorites.
     * @param {string} courseId - The ID of the course.
     * @returns {Promise<void>}
     * 
     */
    async function addCourseToFavorites(courseId) {
        return await store.dispatch('member/addCourseToFavorites', { courseId, $axios, $cookies });
    }

    /**
     * Remove a course from favorites.
     * @param {string} courseId - The ID of the course.
     * @returns {Promise<void>}
     * 
     */
    async function removeCourseFromFavorites(courseId) {
        return await store.dispatch('member/removeCourseFromFavorites', { courseId, $axios, $cookies });
    }

    /**
     * Add an educator to favorites.
     * @param {string} educatorId - The ID of the educator.
     * @returns {Promise<void>}
     * 
     */
    async function addEducatorToFavorites(educatorId) {
        return await store.dispatch('member/addEducatorToFavorites', { educatorId, $axios, $cookies });
    }

    /**
     * Remove an educator from favorites.
     * @param {string} educatorId - The ID of the educator.
     * @returns {Promise<void>}
     * 
     */
    async function removeEducatorFromFavorites(educatorId) {
        return await store.dispatch('member/removeEducatorFromFavorites', { educatorId, $axios, $cookies });
    }

    /**
     * Register a New User
     * @param {object} registraton  member registration object
     * @returns {Promise<void>}
     * 
     */
    async function register(registration) {
        return await store.dispatch('member/register', { registration, $axios, $cookies });
    }

    /**
     * Login Existing Member
     * @param {object} member authentication object
     * @returns {Promise<void>}
     * 
     */
    async function login(authentication) {
        return await store.dispatch('member/login', { authentication, $axios, $cookies });
    }

    /**
     * Check for user by Email Address
     * @param {object} member authentication object
     * @returns {Promise<void>}
     * 
     */
    async function checkForExistingEmail(email) {
        return await store.dispatch('member/checkForExistingEmail', { email, $axios, $cookies });
    }

    /**
     * Validate Email with Pin
     * @param {string} email - The email address of the user.
     * @param {string} pin - The pin sent to the user.
     * @param {string} key - The form key.
     */
    async function validateEmailWithPinAndKey(email, pin, key) {
        return await store.dispatch('member/validateEmailWithPinAndKey', { email, pin, key, $axios, $cookies }); 
    }

    /**
     * Logout Member
     * @param {object} member authentication object
     * @returns {Promise<void>}
     * 
     */
    async function logout() {
        return await store.dispatch('member/logout', { $axios, $cookies });
    }
        
    /**
     * Remove an educator from favorites.
     * @param {string} nonce - The nonce value from the partner.
     * @returns {Promise<void>}
     * 
     */
    async function getMemberFromPartnershipNonce(nonce, partnerId) {
        return await store.dispatch('member/getMemberFromPartnershipNonce', { nonce, partnerId, $axios, $cookies });
    }

    async function loginMemberFromNonce(nonce) {
        return await store.dispatch('member/loginMemberFromNonce', { nonce, $axios, $cookies });
    }

    async function setCartItems(cartItems) {
        return await store.dispatch('member/setCartItems', { cartItems, $axios, $cookies });
    }

    async function removeCartItems(itemId) {
        return await store.dispatch('member/removeCartItems', { itemId, $axios, $cookies });
    }

    /**
     * Remove an educator from favorites.
     * @param {string} nonce - The nonce value from the partner.
     * @returns {Promise<void>}
     * 
     */
    async function getMemberFromPinAndKey(pin, formKey) {
        return await store.dispatch('member/getMemberFromPinAndFormKey', { pin, formKey, $axios, $cookies });
    }

    async function getCart() {
        return await store.dispatch('member/getCart', { $axios, $cookies });
    }

    async function checkOutCart(checkOutObject) {
        return await store.dispatch('member/checkOutCart', { checkOutObject, $axios, $cookies });
    }

    // ## CONFIG ##
    /**
     * Get dark mode status.
     * @returns {boolean} Whether dark mode is enabled.
     */
    function getDarkMode() {
        return false;
    }
    /**
     * Get player config
     * @returns {object} player configuration
     */
    async function getPlayerConfig() {
        return await store.dispatch('member/getPlayerConfiguration', { $axios, $cookies });
    }

    function getPinForm() {
        return store.getters['member/getPinForm'];
    }

    function checkPlayerConfiguration(){
        store.dispatch('member/checkPlayerConfiguration', { $cookies });
    }

    async function getPaymentProfiles() {
        return await store.dispatch('member/getPaymentProfiles', { $axios, $cookies });
    }

    const auth = {
        login: login,
        register: register,
        logout: logout,
        checkForExistingEmail: checkForExistingEmail,
        sendPinToEmail: sendPinToEmail,
        validateEmailWithPinAndKey: validateEmailWithPinAndKey,
        loginMemberFromNonce: loginMemberFromNonce
    };

    const collections = {
        list: getCollections,
        get: getCollection,
        getCollectionsForItem: getCollectionsForItem,
        add: addCollection,
        addCourseToCollection: addCourseToCollection,
        removeCourseFromCollection: removeCourseFromCollection,
        remove: removeCollection,
        archiveItem: archiveItem,
        removeArchivedItem: removeArchivedItem,
        getAvailableCollectionsForItem: getAvailableCollectionsForItem,
    };

    const notifications = {
        getCount: getNotificationsCount,
        get: getNotifications,
        markAsRead: markNotificationsAsRead,
    };

    const cart = {
        get: getCart,
        setItems: setCartItems,
        removeItem: removeCartItems,
        getPaymentProfiles: getPaymentProfiles,
        checkout: checkOutCart
    };

    const favorite ={
        addCourse: addCourseToFavorites,
        removeCourse: removeCourseFromFavorites,
        addEducator: addEducatorToFavorites,
        removeEducator: removeEducatorFromFavorites,
    }

    const config = {
        darkMode: getDarkMode,
        player: getPlayerConfig,
        pinForm: getPinForm,
        checkPlayer: checkPlayerConfiguration
    };

    const partnership = {
        fromNonce: getMemberFromPartnershipNonce,
        fromPinAndKey: getMemberFromPinAndKey
    };

    // Inject the member object functionality into the Vue app
    inject('member', { get, notifications, collections, config, favorite, auth, partnership, cart });
}